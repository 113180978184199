/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import gsap from 'gsap';

import DelayLink from 'components/ui-components/delay-link';

const onDelayStart = () => {
  const elementsToFade = document.querySelectorAll('[data-transition-fade]');
  elementsToFade &&
    gsap.to(elementsToFade, { y: 50, opacity: 0, duration: 0.7 });
};

export default function AnimatedLink(props) {
  const { sx, ...rest } = props;
  return (
    <DelayLink
      delay={800}
      onDelayStart={onDelayStart}
      sx={{
        position: 'relative',
        display: 'inline-block',

        ':before': {
          content: '""',
          width: '100%',
          height: '0.15ch',
          zIndex: -1,
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'currentColor',
          transform: 'matrix(1, 0.05, -0.05, 1, 0, 5)',
          opacity: 0,
          transition: '0.3s all ease-in-out',
        },

        ':hover': {
          ':before': {
            transform: 'matrix(1, 0, 0, 1, 0, 0)',
            opacity: 1,
            transition: '0.3s all ease-in-out',
          },
        },
        ...sx,
      }}
      rel="preload"
      {...rest}
    />
  );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { LogError } from 'business/constants';
import AnimatedLink from 'components/ui-components/animated-link';
import Icon from 'components/ui-components/icon';
import Link from 'components/ui-components/transition-link';
import React, { useEffect } from 'react';
import useSWR from 'swr';
import { Flex, jsx } from 'theme-ui';
import HamburgerMenu from './hamburger-menu';
import { Route, useLocation } from 'react-router-dom';

const request = {
  params: {},
  query: /* groq */ `{
    'workpage': *[_type == 'workpage' && !(_id in path("drafts.**"))]{
      title,
      slug,
      _id
    },
    'newspage': *[_type == 'newspage' && !(_id in path("drafts.**"))]{
      title,
      slug,
      _id
    },
    'careerpage': *[_type == 'careerpage' && !(_id in path("drafts.**"))]{
      title,
      slug,
      _id
    },
    'aboutpage': *[_type == 'page' && _id == 'about' && !(_id in path("drafts.**"))]{
      title,
      slug,
      _id
    },
    'contactpage': *[_type == 'page' && _id == 'contact' && !(_id in path("drafts.**"))]{
      title,
      slug,
      _id
    },
    'startpage': *[_type == 'startpage' && !(_id in path("drafts.**"))]{
      title,
      slug,
      _id
    },
  }`,
};

const HeaderContainer = React.forwardRef((props, ref) => (
  <Flex
    as="nav"
    ref={ref}
    sx={{
      alignItems: 'center',
      justifyItems: 'flex-start',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      flexDirection: 'row',
      zIndex: '200',
      paddingTop: [4, null, 4],
      paddingLeft: [3, 4, 4],
      paddingRight: [3, 4, 4],
      color: 'text',
    }}
    id="js-mainheader"
    {...props}
  />
));

const LogoLink = (props) => {
  return (
    <Link
      aria-label="home"
      to={props?.to || '/'}
      skew={false}
      sx={{
        marginRight: 4,
        flex: '1 auto',
        display: 'block',
      }}
    >
      <Icon symbol="logo" sx={{ display: 'block', width: [56, 82] }} />
    </Link>
  );
};

const getHeaderHeight = () => {
  let mainHeaderHeight = document.getElementById('js-mainheader').offsetHeight;
  return mainHeaderHeight;
};

const Header = (props) => {
  useEffect(() => {
    getHeaderHeight();

    var scrollpos = window.scrollY;
    var header = document.getElementById('js-mainheader');

    function add_class_on_scroll() {
      header.classList.add('section--active');
    }

    function remove_class_on_scroll() {
      header.classList.remove('section--active');
    }

    window.addEventListener('scroll', function () {
      scrollpos = window.scrollY;

      if (scrollpos > 20) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });
  }, []);

  const { data, error } = useSWR([request.query, request.params]);

  if (error || !data) {
    error && LogError(request.query, request.params, error);
    return null;
  }

  const allPages = [
    ...data.workpage,
    ...data.aboutpage,
    ...data.newspage,
    ...data.careerpage,
    ...data.contactpage,
    ...data.startpage,
  ];

  const location = useLocation();

  return (
    <HeaderContainer
      data-page={location.pathname === '/' ? 'home' : 'other'}
      {...props}
    >
      <LogoLink to="/" />
      <Flex
        sx={{
          flex: '1 auto',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          display: ['none', null, 'flex'],
        }}
      >
        {allPages.map((item, i) => {
          if (!item?.slug?.current || !item.title) return null;
          return (
            <AnimatedLink
              key={item._id || i}
              sx={{
                fontFamily: 'navlink',
                display: ['none', null, 'inline-block'],
                marginRight: 4,
              }}
              skew={false}
              to={`/${item.slug.current}`}
            >
              {`${item.title}`}
            </AnimatedLink>
          );
        })}
      </Flex>
      <HamburgerMenu pages={allPages} />
    </HeaderContainer>
  );
};

export default Header;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Text, Grid, Box } from 'theme-ui';
import useSWR from 'swr';
import { LogError } from 'business/constants';
import { useRef, useState } from 'react';
import { Link } from 'components/ui-components/link';
import useEventListener from 'utils/hooks/useEventListener';
import useOutsideClick from 'utils/hooks/useOutsideClick';
import ArrowLink from 'components/ui-components/arrow-link';
import useBreakpoint from 'utils/hooks/useBreakpoint';

const request = {
  params: {},
  query: `*[_type == 'project']{
    title,
    _id,
    slug,
    'categories': categories[]->title,
    }`,
};

const handleScroll = ({ show, hide }) => {
  const header = document.getElementById('js-mainheader');
  if (!header) return false;

  let scrollpos = window.scrollY;
  const header_height = header.offsetHeight + 20;

  scrollpos = window.scrollY;
  if (scrollpos >= header_height) {
    show();
  } else {
    hide();
  }
};

const HamburgerMenu = ({ pages }) => {
  const { data, error } = useSWR([request.query.toString(), request.params]);
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const activeBreakPoint = useBreakpoint();

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  useEventListener('scroll', () => {
    if (activeBreakPoint < 1) {
      return undefined;
    }

    handleScroll({
      show: () => setIsVisible(true),
      hide: () => setIsVisible(false),
    });
  });

  if (error || !data) {
    error && LogError(request.query, request.params, error);
    return null;
  }

  return (
    <Box ref={ref}>
      <Box
        sx={{
          backgroundColor: 'orange',
          position: 'fixed',
          top: 0,
          right: isOpen ? 0 : [0, null, '-1rem'],
          width: ['100%', null, '50%'],
          height: '100vh',
          zIndex: 1000,
          visibility: isOpen ? 'visible' : 'hidden',
          opacity: isOpen ? 1 : 0,
          transition: 'all .3s',
          pointerEvents: isOpen ? 'initial' : 'none',
        }}
      >
        <Grid
          sx={{
            gridTemplateColumns: ['repeat(12, 1fr)', 'repeat(8, 1fr)'],
            paddingTop: ['8rem', '12rem'],
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              gridColumn: ['2 / span 10', '2 / span 3'],
            }}
          >
            {pages.map((item, i) => {
              if (!item?.slug?.current || !item.title) return null;

              return (
                <ArrowLink
                  key={item._id || i}
                  to={`/${item?.slug?.current}` || '/'}
                  onClick={() => setIsOpen(false)}
                >
                  {item.title}
                </ArrowLink>
              );
            })}
          </Flex>
          <Flex
            sx={{
              display: ['none', 'flex'],
              flexDirection: 'column',
              fontSize: '3rem',
              gridColumn: '6 / span 2',
              paddingTop: 2,
            }}
          >
            <Text
              variant="strong"
              sx={{
                marginBottom: '1rem',
                fontSize: [3, 4, null, 5],
              }}
            >
              Case
            </Text>
            {data.map((project) => {
              const { _id, slug, title } = project;

              return (
                <Link
                  key={_id}
                  to={`/work/${slug.current}`}
                  onClick={() => setIsOpen(false)}
                  sx={{
                    fontSize: [3, 4, null, 5],
                  }}
                >
                  {title}
                </Link>
              );
            })}
          </Flex>
        </Grid>
      </Box>
      <Box
        onClick={() => setIsOpen(!isOpen)}
        data-nav="hamburger"
        sx={{
          width: 16,
          height: ['1.9rem', 8],
          boxSizing: 'content-box',
          position: 'fixed',
          right: isVisible ? '4rem !important' : [3, null, '-9rem'],
          top: 4,
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          transition: 'all .3s',
          zIndex: 2000,
          transform: ['none', null, null],
          mixBlendMode: isOpen ? 'unset' : 'difference',

          ':before': {
            content: '""',
            height: 2,
            backgroundColor: 'black',
            width: [12, 16],
            transition: 'all .3s',
            transform: isOpen ? 'translate(0%, 0.7rem) rotate(-45deg)' : null,
          },

          ':after': {
            content: '""',
            height: 2,
            backgroundColor: 'black',
            width: [12, 16],
            transition: 'all .3s',
            transform: isOpen ? 'translate(0%, -0.7rem) rotate(45deg)' : null,
          },
        }}
      ></Box>
    </Box>
  );
};

export default HamburgerMenu;
